<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <div class="ip-input w-full">
      <el-input
        v-model="textData"
        clearable
        autocorrect="off"
        autocapitalize="off"
        autocomplete="nope"
        spellcheck="false"
        :disabled="disabled"
        placeholder="0.0.0.0"
        @update:modelValue="handleFilterInput"
      />
    </div>
  </div>
</template>
<script>
import typeOf from 'just-typeof'

export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      textData: this.modelValue || null
    }
  },
  computed: {
    disabled() {
      const disable = this.filter && this.filter.disable
      if (disable && typeOf(disable) === 'function') {
        if (this.textData) {
          this.setToNull()
        }
      }
      return false
    }
  },
  methods: {
    handleFilterInput(v) {
      this.textData = v.replace(/[^0-9.]/g, '')

      const sections = this.textData.split('.')
      if (
        sections.length > 4 ||
        sections.some((section) => section.length > 3)
      ) {
        this.textData = this.textData.slice(0, -1)
      }

      if (this.validateIpInput(this.textData)) {
        this.$emit('update:modelValue', this.textData)
      }
    },
    setToNull() {
      this.$emit('update:modelValue', null)
      this.textData = null
    },
    validateIpInput(value) {
      // Regular expression to allow only ip valid numbers 0-255.0-255.0-255.0-255
      const regex = /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}$/

      return regex.test(value)
    }
  }
}
</script>
